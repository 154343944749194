<button type="button" class="chat-btn btn btn-soft-leaf rounded-pill mt-0 mb-0" data-bs-toggle="modal" data-bs-target="#modal-02">
  <!-- <img src="../../../../../assets/img/chatbot/chatbot/enquiry-icon.png" alt="chat"> -->
<p>{{msglabel.default.SendEnquiry}}</p>
</button>


<button #button type="button"   data-bs-toggle="modal" data-bs-target="#modal-01" (click)="openmodal()">
  <!-- <img src="../../../../../assets/img/chatbot/chatbot/Circle-icons-chat.svg.png" alt="chat"> -->

</button>
<!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
    <div class="row">
    <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
        <h2 class="display-4 mb-3 text-center">Enquiry Form</h2>
        
        <form class="contact-form needs-validation" [formGroup]="contactForm" (ngSubmit)="saveContact()">
            <div class="messages"></div>
            <div class="row gx-4">
                <div class="col-md-6">
                    <div class="form-floating mb-4">
                        <input type="text" name="name" class="form-control" formControlName="name" required="">
                        <label>Name *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('name')?.hasError('required') && contactForm.get('name')?.touched">
                            Please enter your first name.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-floating mb-4">
                        <input formControlName="email" type="email" class="form-control" required="">
                        <label>Work Email *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('email')?.hasError('required') && contactForm.get('email')?.touched">
                            Please enter email.
                        </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('email')?.hasError('pattern') && contactForm.get('email')?.touched">
                            Please enter valid work email.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-floating mb-4">
                        <input type="tel" class="form-control" formControlName="mobile" required="">
                        <label>Mobile *</label>
                        <div class="form-text text-muted">
                            Format +XX (XXX) XXX-XXXX
                        </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('mobile')?.hasError('required') && contactForm.get('mobile')?.touched">
                            Please enter mobile number.
                        </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('mobile')?.hasError('pattern') && contactForm.get('mobile')?.touched">
                            Please enter valid mobile number.
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-select-wrapper mb-4">
                        <select class="form-select form-control" formControlName="category" required=""
                            *ngIf="contactCategory$ | async as contactCategory">
                            <option value="">Select a Department </option>
                            <option *ngFor="let cc of contactCategory.results" [value]="cc.id">{{cc.name}}
                            </option>
                        </select>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('category')?.hasError('required') && contactForm.get('category')?.touched">
                            Please select a category.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-floating mb-4">
                        <input type="text" class="form-control" formControlName="company" required="">
                        <label>Company Name *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('company')?.hasError('required') && contactForm.get('company')?.touched">
                            Please enter company name.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-floating mb-4">
                        <input type="text" class="form-control" formControlName="designation" required="">
                        <label>Designation *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('designation')?.hasError('required') && contactForm.get('designation')?.touched">
                            Please enter designation.
                        </div>
                    </div>
                </div>
               

                <div class="col-12">
                    <div class="form-floating mb-4">
                        <textarea class="form-control" formControlName="user_message" placeholder="Your message"
                            style="height: 150px" required=""></textarea>
                        <label for="form_message">Message *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback d-block"
                            *ngIf="contactForm.get('user_message')?.hasError('required') && contactForm.get('user_message')?.touched">
                            Please enter message.
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <re-captcha
                        style="display:flex;transform:scale(1.0);-webkit-transform:scale(1.0);transform-origin:0 0;-webkit-transform-origin:0 0;"
                        name="recaptcha" formControlName="recaptcha"> </re-captcha>

                </div>
                <div class="col-12 text-center">
                    <input *ngIf="!formSubmitted" [disabled]="!contactForm.valid" type="submit"
                        class="btn btn-primary rounded-pill btn-send mb-3" value="Send message">

                    <p class="text-muted" *ngIf="formSubmitted">Please Wait...</p>
                    <p class="text-muted"><strong>*</strong> These fields are required.</p>
                </div>
            </div>
        </form>
    </div>
</div>
        </div>
      </div>
    </div>
</div> -->

<div class="modal fade" id="modal-01" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content text-center">
      <div class="modal-body">
        <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="row">
        </div>
        <h4>{{msglabel.default.JoinUs}}</h4>
        <h4>{{msglabel.default.SubscribeourAnnualNewsletter}}</h4>
      </div>
      <div class="newsletter-wrapper">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div id="mc_embed_signup">

              <form class="contact-form needs-validation" method="post" action="./assets/php/contact.php" novalidate=""
                [formGroup]="indexform" (ngSubmit)="saveemail()">
                <div class="messages"></div>

                <div class="row gx-4">
                  <div class="col-md-12">
                    <div class="form-floating mb-4">
                      <!-- <input id="form_name" type="email" name="name" class="form-control" placeholder="Jane" required=""
                        formControlName="email"> -->
                        <input autocomplete="off" type="email" value="" name="EMAIL"
                                    class="required email form-control" placeholder="Email Address" id="mce-EMAIL2"
                                    formControlName="email">
                      <label for="form_name">{{msglabel.default.Email}} *</label>
                      <div class="invalid-feedback d-block"
                        *ngIf="indexform.get('email')?.hasError('required') && indexform.get('email')?.touched">
                        {{msglabel.default.Pleaseenteremail}}
                      </div>
                      <div class="invalid-feedback d-block"
                        *ngIf="indexform.get('email')?.hasError('pattern') && indexform.get('email')?.touched">
                        {{msglabel.default.Pleaseentervalidworkemail}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group mb-4">
                    <re-captcha
                      style="display:flex;transform:scale(1.0);-webkit-transform:scale(1.0);transform-origin:0 0;-webkit-transform-origin:0 0;"
                      name="recaptcha" formControlName="recaptcha">
                    </re-captcha>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <input *ngIf="!formSubmitted" [disabled]="!indexform.valid" type="submit"
                    class="btn btn-primary rounded-pill btn-send mb-3" value="{{msglabel.default.Join}}">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-02" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content text-center">
      <div class="modal-body">
        <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="row">
        </div>
        <h3>{{msglabel.default.EnquiryForm}}</h3>
        <!-- <p class="mb-6"></p> -->
        <div class="newsletter-wrapper">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div id="mc_embed_signup">

                <form class="contact-form needs-validation" method="post" action="./assets/php/contact.php"
                  novalidate="" [formGroup]="contactForm" (ngSubmit)="saveContact()">
                  <div class="messages"></div>
                  <div class="row gx-4">
                    <div class="col-md-12">
                      <div class="form-floating mb-4">
                        <input id="form_name" type="text" name="name" class="form-control" placeholder="Jane"
                          required="" formControlName="name">
                        <label for="form_name">{{msglabel.default.Name}} *</label>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('name')?.hasError('required') && contactForm.get('name')?.touched">
                          {{msglabel.default.PleaseenteryourName}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating mb-4">
                        <input id="form_lastname" type="email" name="email" class="form-control"
                          placeholder="jane.doe@example.com" required="" formControlName="email">
                        <label for="form_lastname">{{msglabel.default.WorkEmail}} *</label>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('email')?.hasError('required') && contactForm.get('email')?.touched">
                          {{msglabel.default.Pleaseenteremail}}
                        </div>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('email')?.hasError('pattern') && contactForm.get('email')?.touched">
                          {{msglabel.default.Pleaseentervalidworkemail}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating mb-4">
                        <input id="form_email" type="number" name="mobile" class="form-control" placeholder="1234567890"
                          required="" formControlName="mobile">
                        <label for="form_email">{{msglabel.default.MobileNo}} *</label>
                        <div class="form-text text-muted">
                          {{msglabel.default.FormatXXXXXXXXXXXX}}
                        </div>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('mobile')?.hasError('required') && contactForm.get('mobile')?.touched">
                          {{msglabel.default.Pleaseentermobilenumber}}
                        </div>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('mobile')?.hasError('pattern') && contactForm.get('mobile')?.touched">
                          {{msglabel.default.Pleaseentervalidmobilenumber}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-select-wrapper mb-4">
                        <select class="form-select" id="form-select" name="category" required=""
                          formControlName="category">
                          <ng-container *ngIf="contactCategory$ | async as contactCategory">
                            <option value="">{{msglabel.default.SelectaDepartment}}</option>
                            <option *ngFor="let cc of contactCategory.results" [value]="cc.id">{{cc.name}}
                            </option>
                          </ng-container>
                        </select>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('category')?.hasError('required') && contactForm.get('category')?.touched">
                          {{msglabel.default.Pleaseselectacategory}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating mb-4">
                        <input id="form_email" type="text" name="company" class="form-control" placeholder="google"
                          required="" formControlName="company">
                        <label for="form_email">{{msglabel.default.Company}} *</label>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('company')?.hasError('required') && contactForm.get('company')?.touched">
                          {{msglabel.default.Pleaseentercompanyname}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating mb-4">
                        <input id="form_email" type="text" name="designation" class="form-control"
                          placeholder="Tech Lead" required="" formControlName="designation">
                        <label for="form_email">{{msglabel.default.Designation}} *</label>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('designation')?.hasError('required') && contactForm.get('designation')?.touched">
                          {{msglabel.default.Pleaseenterdesignation}}
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating mb-4">
                        <textarea id="form_message" name="user_message" class="form-control" placeholder="Your message"
                          style="height: 150px" required="" formControlName="user_message"></textarea>
                        <label for="form_message">{{msglabel.default.Message}} *</label>
                        <div class="invalid-feedback d-block"
                          *ngIf="contactForm.get('user_message')?.hasError('required') && contactForm.get('user_message')?.touched">
                          {{msglabel.default.Pleaseentermessage}}
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group mb-4">
                        <re-captcha
                          style="display:flex;transform:scale(1.0);-webkit-transform:scale(1.0);transform-origin:0 0;-webkit-transform-origin:0 0;"
                          name="recaptcha" formControlName="recaptcha">
                        </re-captcha>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <input *ngIf="!formSubmitted" [disabled]="!contactForm.valid" type="submit"
                        class="btn btn-primary rounded-pill btn-send mb-3" value="Send message">

                      <p class="text-muted" *ngIf="formSubmitted">{{msglabel.default.PleaseWait}}</p>
                      <p class="text-muted"><strong>*</strong> {{msglabel.default.Thesefieldsarerequired}}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>