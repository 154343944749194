<ng-container>
  <div class="subscription-banner">
    <div class="centered">{{ ProductName }}</div>
  </div>

  <div class="container mt-4">
    <div class="heading ">
      <p class="heading-name">{{ msglabel.default.subscription }}</p>
    </div>
  </div>
  <div id="cards-background">
    <div class="cards-row cards mt-5 d-flex justify-content-center gap-8 p-5">
      <!-- card 1 start-->
      <div class="card card-basic-body shadow-lg">
        <div class="card-header card-basic-header border-0 fs-26 fw-bolder">
            {{ msglabel.default.Basic }}  
        </div>
        <div class="p-5 card-basic-body">
          <div class="px-2 card-basic-body">
            <p class="card-basic-text">
                {{ msglabel.default.cardBasicText }}
            </p>
            <div class="d-flex gap-1">
              <h1 class="fs-40">
                {{
                  priceId["monthly-recurring"].price[countryCurrency]
                    | currency : countryCurrency : true : "2.0"
                }}
              </h1>
              <h5 class="align-content-center mt-3">/{{ msglabel.default.month }}</h5>
            </div>
            <div>
              <span
                class="d-flex align-items-center Qty-Block w-16 h-8 shadow-lg qty-shadow"
              >
                <span
                  class="fw-bolder shadow-lg qty-shadow"
                  style="color: #090909; margin-left: 10px"
                  >{{ msglabel.default.qty }}:
                </span>
                <select
                  class="form-select form-select-sm w-12 border-0 btn-sm h-8 shadow-lg qty-shadow"
                  (change)="onMonthlyRecurringQtyChange($event)"
                >
                  <option
                    *ngFor="let number of monthlyRecurring"
                    [value]="number"
                  >
                    {{ number }}
                  </option>
                </select>
              </span>
            </div>
            <div class="card-bottom mt-3">
              <button
                class="btn w-100 h-10 card-basic-btn"
                (click)="goToCheckOut(1)"
                id="press"
                [style.display]="cardOneBtn ? 'block' : 'none'"
              >
                {{ msglabel.default.choosePlan }}
              </button>
              <button
                class="btn btn-primary w-100 h-10"
                type="button"
                [style.display]="cardOneBtn ? 'none' : 'block'"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
            <div class="mt-2 card-basic-list">
                <div class="d-flex">
                    <li></li>
                    <span>{{ msglabel.default.cardbasiclist1 }}</span>
                 </div>
                 <div class="d-flex">
                    <li></li>
                    <span>{{ msglabel.default.cardBasiclist2 }}</span>
                 </div>
            </div>
          </div>
        </div>
        <div
          class="card-basic-footer w-100 h-3 position-absolute bottom-0 fs-10"
        ></div>
      </div>
      <!-- card end-->
      <!-- card 2 start-->
      <div class="card border-0 card-professional-body shadow-hover">
        <button
          class="position-absolute top-0 start-50 translate-middle badge rounded-pill border-0 w-17 badge"
        >
        {{ msglabel.default.mostPopular}}
        </button>
        <div
          class="card-header card-professional-header border-0 fs-25 fw-bolder"
        >
        {{ msglabel.default.Professional}}
        </div>
        <div class="p-5">
          <div class="px-2">
            <p class="card-professional-text">
                {{ msglabel.default.cardProfessionalText}}
            </p>
            <div class="d-flex gap-1">
              <h1 class="fs-40">
                {{
                  priceId["yearly-recurring"].price[countryCurrency]
                    | currency : countryCurrency : true : "2.0"
                }}
              </h1>
              <h5 class="align-content-center mt-3">/{{msglabel.default.year}}</h5>
            </div>
            <div>
              <span
                class="d-flex align-items-center Qty-Block w-16 h-8 shadow-lg qty-shadow"
              >
                <span
                  class="fw-bolder"
                  style="color: #090909; margin-left: 10px"
                  >{{ msglabel.default.qty }}:
                </span>
                <select
                  class="form-select form-select-sm w-12 border-0 btn-sm"
                  (change)="onYearlyRecurringQtyChange($event)"
                >
                  <option
                    *ngFor="let numbers of yearlyRecurring"
                    [value]="numbers"
                  >
                    {{ numbers }}
                  </option>
                </select>
              </span>
            </div>
            <div class="card-bottom mt-3">
              <button
                class="btn w-100 h-10 card-professional-btn"
                (click)="goToCheckOut(2)"
                id="press"
                [style.display]="cardTwoBtn ? 'block' : 'none'"
              >
                {{ msglabel.default.choosePlan }}
              </button>
              <button
                class="btn btn-primary w-100 h-10"
                type="button"
                [style.display]="cardTwoBtn ? 'none' : 'block'"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
            <div class="mt-2 card-professional-list">
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardProfessionalList1 }}</span>
              </div>
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardProfessionalList2 }}</span>
              </div>
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardProfessionalList3 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-professional-footer w-100 h-3 position-absolute bottom-0 fs-10"
        ></div>
      </div>
      <!-- card end-->
      <!-- card 3 start-->
      <div class="card border-0 card-enterprise-body shadow-hover">
        <div
          class="card-header card-enterprise-header border-0 fs-25 fw-bolder"
        >
        {{ msglabel.default.Enterprise }}
        </div>
        <div class="p-5">
          <div class="px-2">
            <p class="card-enterprise-text">
                {{ msglabel.default.cardEnterpriseText }}
            </p>
            <div class="d-flex">
              <h1 class="align-content-center  fs-40">{{ msglabel.default.customPrice }}</h1>
            </div>
            <div class="h-7"></div>
            <div class="card-bottom mt-3">
              <button
                class="btn w-100 h-10 card-enterprise-btn"
                routerLink="/contact-us"
              >
              {{ msglabel.default.contactUs }}
              </button>
            </div>
            <div class="mt-2 card-enterprise-list">
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardEnterpriseList1 }}</span>
              </div>
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardEnterpriseList2 }}</span>
              </div>
              <div class="d-flex">
                <li></li>
                <span>{{ msglabel.default.cardEnterpriseList3 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-enterprise-footer w-100 h-3 position-absolute bottom-0 fs-10"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
